<template>
  <iframe-nick :src="booturl" />
</template>
<script>
import iframenick from "./iframenick";
export default {
  props: {
    booturl: {
      type: String,
      required: true,
    },
  },
  components: {
    "iframe-nick": iframenick,
  },
  data: () => ({
    items: [],
  }),
};
</script>